<template>
        <base-page>

        <el-card  class="filtre-albastre no-padding-card" >
            <el-collapse>
                <el-collapse-item  name="1">
                    <template slot="title">
                        <el-button style="margin-right: 15px;" icon="el-icon-plus" size="mini" type='success'  @click='show_dialog()'>
                        Locatie
                        </el-button>
                        FILTRE
                    </template>
                    <div class="filtre">
                        <el-form @submit.prevent.native='refresh_info()'>
                            <el-row :gutter="20">
                                
                                <el-col :md='4'>
                                    <el-form-item label='Client' >
                                        <el-select id="TxtClient" class='full-width' @change="change_client()" v-model='Filters.IdClient' filterable :clearable="true" @clear="select_toti_clientii()">
                                            <el-option label='Toti' value='-1'/>
                                            <el-option v-for='item in Info.Clienti' :key="'clienti' + item.Id" :value='item.Id' :label="item.Nume" >
                                                <span style="float: left">{{ item.Nume }}</span>
                                                <span v-if="item.Sters == '1'" style="float: right; color: red; font-size: 13px"> 
                                                    <el-tag type="danger" size="mini">STERS</el-tag>
                                                </span>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='2'>
                                    <el-form-item label='Locatie' >
                                        <el-input v-model='Filters.Nume'  placeholder="Nume locatie..." clearable/>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3'>
                                    <el-form-item label='Firma client' >
                                        <el-input v-model='Filters.FirmaClient'  placeholder="Firma client" clearable/>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='3'>
                                    <el-form-item label='Firma GPH' >
                                        <el-select v-model="Filters.IdFirma" @change='refresh_info()' class='full-width' style='margin-bottom:10px'>
                                            <el-option value="-1" label="Toate" />
                                            <el-option v-for="(f,idx) in Info.firme" :key="'firma-' + idx" :value="f.Id" :label="f.Nume"/>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='2' :xs='24'>
                                    <el-form-item label='Stersi' >
                                        <el-select v-model="Filters.Stersi" @change='refresh_info()' class='full-width' style='margin-bottom:10px'>
                                            <el-option value="0" label="Activi"></el-option>
                                            <el-option value="1" label="Stersi"></el-option>
                                            <el-option value="-1" label="Toti"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :md='3' :xs='24'>
                                    <el-form-item label='Prelungire automata' >
                                        <el-select v-model="Filters.PrelungireAutomata" @change='refresh_info()' class='full-width' style='margin-bottom:10px'>
                                            <el-option value="1" label="Doar cu prelungire automata"></el-option>
                                            <el-option value="0" label="Fara prelungire automata"></el-option>
                                            <el-option value="-1" label="Toti"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                
                                <el-col :md='2' :xs='24'>
                                    <el-form-item label='Operat / Validat' >
                                        <el-select v-model="Filters.Operat" @change='refresh_info()' class='full-width' style='margin-bottom:10px'>
                                            <el-option value="1" label="Da"></el-option>
                                            <el-option value="0" label="Nu"></el-option>
                                            <el-option value="-1" label="Toate"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='2' :xs='12' class='filter-buttons' >
                                    <el-form-item :label="PaginationInfo.RowCount + ' items'"" >
                                        <el-button size="mini" type='primary' native-type='submit' @click='refresh_info()'> Cauta </el-button>
                                    </el-form-item>
                                </el-col>

                                <el-col :md='2' :xs='12' class='filter-buttons' v-if='$has_right("vizualizare_mentenanta")' >
                                    <el-form-item label="TOTAL" >
                                        {{Total.Lei | moneyformat}} LEI <br/>
                                        {{Total.Eur | moneyformat}} EUR
                                    </el-form-item>
                                </el-col>



                            </el-row>
                        </el-form>
                        </div>
                </el-collapse-item>
            </el-collapse>
        </el-card>
        

        <el-table :data="Results" @sort-change='on_sort_change'  >
            <el-table-column sortable="custom" prop='Id' label='Id'  min-width='60'></el-table-column>
            <el-table-column sortable prop='Client' label='Client'  min-width='150'>
                <template slot-scope="scope">
                    <span style="font-weight: bold">{{scope.row.Client}} </span>
                    <div v-if="scope.row.Societate != ''">
                        <el-tag size="mini">{{scope.row.Societate}}</el-tag>
                    </div>
                </template>

            </el-table-column>
            <el-table-column sortable prop='Nume' label='Nume' min-width='150'>
                <template slot-scope="scope">
                    <span style="font-weight: bold">{{scope.row.Nume}} </span>
                    <br/>
                    <el-tag size="mini" style="font-weight: bold !important; ">{{scope.row.Firma}}</el-tag>
                    &nbsp;
                    <el-tag v-if="scope.row.PrelungireAutomata=='1'" size="mini" style="font-weight: bold !important; " type="warning">Prelungire automata</el-tag>

                    <el-tag v-if="scope.row.Sters == '1'" style="margin-left: 5px" type="danger" size="mini">STERS</el-tag>

                    <el-tag v-if="scope.row.OperatInFacturier == '1'" style="margin-left: 5px" type="success" size="mini">Operat</el-tag>
                    <el-tag v-if="scope.row.OperatInFacturier == '0'" style="margin-left: 5px" type="danger" size="mini">Nevalidat</el-tag>
                    
                    
                </template>
            </el-table-column>
            <el-table-column label='Luni neplatite' min-width='150' align="center">
                <template slot-scope="scope">
                    <el-tag style="font-weight: bold !important; width: 50px !important" :type="scope.row.NrLuniNeplatite > 3 ? 'danger' : 'success' ">{{scope.row.NrLuniNeplatite}}</el-tag>
                </template>
            </el-table-column>
            
            <el-table-column prop='DataCreare' label="Data Creare" min-width="150"/>
            <el-table-column prop='UserCreare' label="User Creare"  min-width="150"/>

            <el-table-column  label="Mentenanta"  min-width="150" align="right"  v-if='$has_right("vizualizare_mentenanta")'>
                <template slot-scope="scope">
                    <el-tag size="mini">
                        {{scope.row.ValoareMentenantaLunara}} {{scope.row.NumeValuta}}
                    </el-tag>
                </template>
            </el-table-column>


            <el-table-column fixed="right" label="Actiuni" width="200" v-if="!is_mobile()">
                <template slot-scope="scope" >
                    
                    <el-tooltip content="Alege client default">
                        <el-button type="warning" size='mini' icon="el-icon-right"  circle @click="alege_client(scope.row.IdClient, scope.row.Client)" />
                    </el-tooltip>

                    <el-tooltip content="Taskuri client">
                        <el-button  size='mini' type="success" icon="el-icon-alarm-clock"  circle @click="show_dialog_taskuri(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Modifica">
                        <el-button type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Sterge">
                        <el-button  type="danger" v-if="$has_right('stergere_locatie') && scope.row.Sters=='0'" icon="el-icon-delete"  size='mini' circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                    
                    <el-tooltip content="Reactivare">
                        <el-button type="warning" v-if="scope.row.Sters=='1'" icon="el-icon-upload2"  size='mini' circle @click="recover_item(scope.row)" />
                    </el-tooltip>
                    <br/>
                    <br/>
                    <el-tooltip content="Link autorizare e-factura">
                        <el-button  size='mini'  @click="link_efactura(scope.row)"  style="font-size:8px">
                            Link E-factura
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="Refresh token JWT e-factura" v-if="scope.row.Efactura_Jwt == 1">
                        <el-button  size='mini'  @click="refresh_token(scope.row)" style="font-size:8px" >
                            Refresh JWT
                        </el-button>
                    </el-tooltip>

                </template>
            </el-table-column>

            <el-table-column fixed="right" label="Act." width="50" v-if="is_mobile()">
                <template slot-scope="scope" >
                    
                    <el-popover placement="bottom" width="150" trigger="click">
                        <div>
                            <el-tooltip content="Alege client default">
                                <el-button type="warning" size='mini' icon="el-icon-right"  circle @click="alege_client(scope.row.IdClient, scope.row.Client)" />
                            </el-tooltip>

                            <el-tooltip content="Taskuri client">
                                <el-button  size='mini' type="success" icon="el-icon-alarm-clock"  circle @click="show_dialog_taskuri(scope.row.Id)" />
                            </el-tooltip>

                            <el-button type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                            
                            <el-button  type="danger" v-if="$has_right('stergere_locatie') && scope.row.Sters=='0'" icon="el-icon-delete"  size='mini' circle @click="delete_item(scope.row)" />

                            <el-button type="warning" v-if="scope.row.Sters=='1'" icon="el-icon-upload2"  size='mini' circle @click="recover_item(scope.row)" />

                            <br/><br/>        
                            <el-button  size='mini'  @click="link_efactura(scope.row)" >
                                Link e-factura
                            </el-button>
                        
                        </div>
                        <el-button slot="reference" style="float:right; padding: 10px !important; margin-left: 5px" icon="el-icon-arrow-down"  size='mini'/>
                    </el-popover>

                </template>
            </el-table-column>
        </el-table>
        
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />

        <el-dialog title="Link autorizare E-Factura" :visible.sync="dialogVisible" width="40%">
            <div>
                <input type="text" id='link-spv' :value="linkSPV" class="full-width"/>
                <br/><br/>
                <el-button type="success" size="mini" @click="copy_link_spv">copy</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">OK</el-button>
            </span>
        </el-dialog>

        <Locatii-dialog ref='dlg' @save="refresh_info()" />
        <Taskuri-dialog ref='dlg_taskuri' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Locatii_dialog from '@/pages/locatii/Locatii_dialog.vue';
import Taskuri_dialog from '@/pages/locatii/Taskuri_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';
import eventBus from '@/backend/EventBus';

export default {
    name: "locatii",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Locatii-dialog': Locatii_dialog,
        'Taskuri-dialog': Taskuri_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            dialogVisible:false,
            linkSPV:'',
            Results: [],
            base_url: '',
            Info: { Clienti: [], firme: [] },
            Total: {
                Lei: 0,
                Eur: 0,
            },
            Filters: { FirmaClient: "", IdFirma: "-1", IdClient: "-1", Client: '' , Nume: '' , Societate: '', Stersi: "0", PrelungireAutomata:"-1", Operat: "-1" },
            OrderBy: { Id: 'DESC', Client: '', Nume: '', Societate: '' },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info() {
            var response        = await this.post("locatii/get_info" );
            this.Info.Clienti   = response.clienti;
            this.Info.firme     = response.firme;

            var id_client_selectat      = settings.get_id_client_selectat();
            var nume_client_selectat    = settings.get_nume_client_selectat();
            if( id_client_selectat != null && id_client_selectat != '' ){
                this.Filters.IdClient = id_client_selectat;
                this.Filters.Client   = nume_client_selectat;
            }

            this.refresh_info();
        },

        async refresh_info() {
            var response        = await this.post("locatii/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            this.Total          = response.Total;
            //
            this.select_menu_item('locatii');
        },
        async refresh_token(row){
            var response        = await this.post("locatii/refresh_token", { Id: row.Id } );
            if(response.Error){
                this.$message.error(response.ErrorMessage );
            }else{
                this.$message("Token refreshed!");
                this.refresh_info();
            }
        },
        reset() {
            this.Filters = { IdClient:-1, Client: '' , Nume: '' , Societate: '' };
            this.refresh_info();
        },

        link_efactura(item){
            this.linkSPV        = item.LinkAutorizareSPV;
            this.dialogVisible  = true;
        },
        copy_link_spv(){
            var copyText = document.querySelector("#link-spv");
            copyText.select();
            document.execCommand("copy");
            this.$message('Am copiat ' + copyText.value);
        },
        
        on_sort_change({ column, prop, order }){

            this.OrderBy    = {};
            var columnName  = prop;
            var orderColumn = 'ASC';

            if( order == 'descending'   ) orderColumn = 'DESC';
            if( order == null           ) orderColumn = '';

            this.OrderBy[columnName] = orderColumn;
            this.refresh_info();
        },

        alege_client(id, nume){
            settings.set_id_client_selectat  (id);
            settings.set_nume_client_selectat(nume);

            eventBus.$emit('change-client-event');

            this.get_info();
        },

        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("locatii/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        async recover_item( item ) {
            var confirm =  await this.$confirm(`Sunteti sigur ca reactivati locatia?`, 'Warning');
            if( confirm )
            {
                await this.post("locatii/recover_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        async change_client() {
            this.Filters.Stersi = "-1";
            this.refresh_info();
        },

        show_dialog (id) {
            this.$refs.dlg.show_me(id);
        },

        show_dialog_taskuri (id_locatie) {
            this.$refs.dlg_taskuri.show_me(id_locatie);
        },

        async select_toti_clientii(){
            this.Filters.IdClient = "-1";
            this.refresh_info();
        },

        
    },
    mounted(){
        this.base_url = settings.BASE_URL;

        if( !this.$has_right("pagina_de_locatii") )  this.$router.push("/no-rights");

        this.get_info();

        eventBus.$on('clear-client-event', () => {
            this.get_info();
        });
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
